import { Component, Fragment } from "react";
import { Button, Container, Header, Label, List, Segment } from "semantic-ui-react";

class Holiday extends Component {
    render() {
        return <Fragment>

            <Container>
                <br />
                <Segment >

                    <Header>
                        Slovakia Holiday
                    </Header>

                    <Label>

                        Dates: 17th - 20th November
                    </Label>
                    <br />
                    <br />
                    <Button a='www.booking.com/hotel/sk/palarikova-whole-apartment-in-center.en-gb.html?aid=390156&label=duc511jc-1FCAMY7wcoggI47QJICVgDaFCIAQGYAQm4ARfIAQzYAQHoAQH4AQKIAgGoAgO4ApXpwagGwAIB0gIkNGZmNTY5YWQtMDBiYy00ZmM0LWIyZjUtYmI1YzQ0NDBjNGEy2AIF4AIB&sid=93599aa0cd174907800fc5cc23b40ea8&all_sr_blocks=1069116201_379412329_4_0_0%3Bcheckin%3D2023-11-17%3Bcheckout%3D2023-11-20%3Bdest_id%3D-843247%3Bdest_type%3Dcity%3Bdist%3D0%3Bgroup_adults%3D3%3Bgroup_children%3D0%3Bhapos%3D3%3Bhighlighted_blocks%3D1069116201_379412329_4_0_0%3Bhpos%3D3%3Bmatching_block_id%3D1069116201_379412329_4_0_0%3Bno_rooms%3D1%3Breq_adults%3D3%3Breq_children%3D0%3Broom1%3DA%2CA%2CA%3Bsb_price_type%3Dtotal%3Bsr_order%3Dprice%3Bsr_pri_blocks%3D1069116201_379412329_4_0_0__19260%3Bsrepoch%3D1695599100%3Bsrpvid%3D87e4a6e3aeff00dc%3Btype%3Dtotal%3Bucfs%3D1'>Hotel link</Button>

                    <List>
                        <li><Label>Shit to do</Label></li>
                        <li>Tech museum (boring as fuck)</li>
                        <li>Hike to rock</li>
                        <li>Zoo (fuck zebra)</li>
                        <li>castle.</li>


                    </List>

                    Probably snowy as fuck so bring cold clothes <br />
                    if we ski we'll need snow suits if the resort dont got em


                    <br />
                    if we take more than 3 we'll book an apartment
                </Segment>

            </Container>
        </Fragment>
    }
}

export default Holiday;